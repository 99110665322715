import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to Reittireiska!</h1>
      <p>Create and share your bouldering and rope routes with ease.</p>
      <div className="features">
        <div className="feature">
          <Link to="/create-route" className="feature-link">
            <h2>Create Routes</h2>
            <p>Design your own bouldering and rope routes by selecting holds and then tapping the image where you want to place the hold.</p>
          </Link>
        </div>
        <div className="feature">
          <Link to="/view-routes" className="feature-link">
            <h2>View Routes</h2>
            <p>Browse and view routes created by others. Filter by wall and difficulty to find the perfect challenge.</p>
          </Link>
        </div>
      </div>
      {/* Add your name at the bottom right */}
      
    </div>
  );
};

export default Home;
