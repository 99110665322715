import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import CreateRoute from './components/CreateRoute';
import ViewRoutes from './components/ViewRoutes';
import './App.css';
import { Analytics } from '@vercel/analytics';

function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/create-route">Create Route</Link>
            </li>
            <li>
              <Link to="/view-routes">View Routes</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-route" element={<CreateRoute />} />
          <Route path="/view-routes" element={<ViewRoutes />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
