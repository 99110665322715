import React, { useState } from 'react';
import './CreateRoute.css';

const CreateRoute = () => {
  const [selectedImage, setSelectedImage] = useState('');
  const [routeName, setRouteName] = useState('');
  const [difficulty, setDifficulty] = useState('6a');
  const [comment, setComment] = useState('');
  const [holds, setHolds] = useState([]);
  const [color, setColor] = useState('red'); // Default color is now red
  const [shape, setShape] = useState('circle');
  const [fill, setFill] = useState('outline'); // Default fill is now outline
  const [size, setSize] = useState('medium'); // Default size is now medium
  const [holdType, setHoldType] = useState('normal');
  const [isSaving, setIsSaving] = useState(false); // State to track saving status

  const images = [
    'images/Overhang.jpg',
    'images/Tatti (1).jpg',
    'images/Tatti (2).jpg',
    'images/Tatti (3).jpg',
    'images/Tatti (4).jpg',
    'images/Wave.jpeg',
    'images/slab.jpg',
    'images/Rope (1).jpg',
    'images/Rope (2).jpg',
    'images/Rope (3).jpg',
    'images/Rope (4).jpg',
    'images/Ryhmis.jpg'
  ];

  const handleImageClick = (e) => {
    if (!selectedImage) return;

    if (holds.length >= 30) {
      alert("You cannot add more than 30 holds.");
      return;
    }

    const rect = e.target.getBoundingClientRect();
    const aspectRatio = rect.width / rect.height;

    // Adjust the x and y coordinates based on the aspect ratio of the image
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    setHolds([...holds, { x, y, color, shape, fill, size, type: holdType }]);
  };

  const handleSaveRoute = () => {
    if (!selectedImage || !routeName || !difficulty) {
      alert('Please fill out all fields.');
      return;
    }

    setIsSaving(true); // Set saving state to true

    const newRoute = {
      name: routeName,
      difficulty,
      comment,
      image: selectedImage,
      holds,
    };

    fetch('https://bouldering-backend.vercel.app/api/routes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newRoute),
    })
      .then(response => response.json())
      .then(data => {
        alert('Route saved successfully!');
        // Reset form
        setRouteName('');
        setDifficulty('6a');
        setComment('');
        setSelectedImage('');
        setHolds([]);
      })
      .catch(error => {
        console.error('Error saving route', error);
        alert('Failed to save route.');
      })
      .finally(() => {
        setIsSaving(false); // Reset saving state
      });
  };

  const handleUndo = () => {
    setHolds(holds.slice(0, -1));
  };

  return (
    <div className="create-route-container">
      <h1>Create Your Route</h1>
      <input
        type="text"
        placeholder="Route Name"
        value={routeName}
        onChange={(e) => setRouteName(e.target.value)}
      />
      <textarea
        placeholder="Comment"
        className="comment-box"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className="dropdown-group">
        <div className="control-group">
          <label>Grade:</label>
          <select value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
            <option value="All">All</option>
            <option value="4">4</option>
            <option value="4+">4+</option>
            <option value="5">5</option>
            <option value="5+">5+</option>
            <option value="6a">6a</option>
            <option value="6a+">6a+</option>
            <option value="6b">6b</option>
            <option value="6b+">6b+</option>
            <option value="6c">6c</option>
            <option value="6c+">6c+</option>
            <option value="7a">7a</option>
            <option value="7a+">7a+</option>
            <option value="7b">7b</option>
            <option value="7b+">7b+</option>
            <option value="7c">7c</option>
            <option value="7c+">7c+</option>
            <option value="8a">8a</option>
            <option value="8a+">8a+</option>
            <option value="8b">8b</option>
            <option value="8b+">8b+</option>
            <option value="8c">8c</option>
            <option value="8c+">8c+</option>
            <option value="9a">9a</option>
            <option value="something fun">something fun</option>
          </select>
        </div>
        <div className="control-group">
          <label>Color:</label>
          <select value={color} onChange={(e) => setColor(e.target.value)}>
            <option value="red">Red</option>
            <option value="green">Green</option>
            <option value="blue">Blue</option>
            <option value="yellow">Yellow</option>
          </select>
        </div>
        <div className="control-group">
          <label>Shape:</label>
          <select value={shape} onChange={(e) => setShape(e.target.value)}>
            <option value="circle">Circle</option>
            <option value="square">Square</option>
          </select>
        </div>
        <div className="control-group">
          <label>Fill:</label>
          <select value={fill} onChange={(e) => setFill(e.target.value)}>
            <option value="filled">Filled</option>
            <option value="outline">Outline</option>
          </select>
        </div>
        <div className="control-group">
          <label>Size:</label>
          <select value={size} onChange={(e) => setSize(e.target.value)}>
            <option value="small">Small</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
          </select>
        </div>
        <div className="control-group">
          <label>Type:</label>
          <select value={holdType} onChange={(e) => setHoldType(e.target.value)}>
            <option value="normal">Normal</option>
            <option value="start">Start</option>
            <option value="top">Top</option>
          </select>
        </div>
      </div>
      <div className="image-selection-container">
        <h2>Select wall image</h2>
        <div className="image-selection">
          {images.map((image, index) => (
            <img
              key={index}
              src={process.env.PUBLIC_URL + `/${image}`}
              alt={`Wall ${index + 1}`}
              className={`image-preview ${selectedImage === image ? 'selected' : ''}`}
              onClick={() => setSelectedImage(image)}
            />
          ))}
        </div>
      </div>
      {selectedImage && (
        <div className="hold-container">
          <h2>Selected Image</h2>
          <div className="image-wrapper" onClick={handleImageClick}>
            <img src={process.env.PUBLIC_URL + `/${selectedImage}`} alt="Selected Wall" className="selected-image" />
            {holds.map((hold, index) => (
              <div
                key={index}
                className={`hold ${hold.shape} ${hold.fill} ${hold.size} ${hold.type}`}
                style={{
                  top: `${hold.y}%`,
                  left: `${hold.x}%`,
                  backgroundColor: hold.fill === 'filled' ? hold.color : 'transparent',
                  borderColor: hold.color,
                }}
              >
                {hold.type === 'start' && <span className="hold-label">S</span>}
                {hold.type === 'top' && <span className="hold-label">T</span>}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="button-group">
        <button className="undo-button" onClick={handleUndo}>Undo</button>
        <button className="save-button" onClick={handleSaveRoute} disabled={isSaving}>
          {isSaving ? 'Saving...' : 'Save Route'}
        </button>
      </div>
    </div>
  );
};

export default CreateRoute;
  