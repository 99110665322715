import React from 'react';
import './RouteCard.css';

const RouteCard = ({ route, onClick }) => {
  return (
    <div className="route-card" onClick={onClick}>
      <div className="route-card-image-wrapper" style={{ position: 'relative' }}>
        <img src={`${process.env.PUBLIC_URL}/${encodeURIComponent(route.image)}`} alt={route.name} className="route-card-image" />
        {route.holds.map((hold, index) => (
          <div
            key={index}
            className={`hold ${hold.shape} ${hold.fill} ${hold.size} ${hold.type}`}
            style={{
              position: 'absolute',
              top: `${hold.y}%`,
              left: `${hold.x}%`,
              transform: 'translate(-50%, -50%)', // Centers the hold
              backgroundColor: hold.color,
              width: hold.size === 'small' ? '10px' : hold.size === 'medium' ? '15px' : '20px',
              height: hold.size === 'small' ? '10px' : hold.size === 'medium' ? '15px' : '20px',
              borderRadius: '50%',
            }}
          />
        ))}
      </div>
      <h3>{route.name} - {route.difficulty}</h3>
      <p>{route.comment}</p>
    </div>
  );
};

export default RouteCard;
