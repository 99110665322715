import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RouteCard from './RouteCard';
import RouteModal from './RouteModal';
import './ViewRoutes.css';

const ViewRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [searchWall, setSearchWall] = useState('All');
  const [searchDifficulty, setSearchDifficulty] = useState({ min: 'All', max: 'All' });
  const [walls, setWalls] = useState([]);

  useEffect(() => {
    axios.get('https://bouldering-backend.vercel.app/api/routes')
      .then(response => {
        setRoutes(response.data);
        setFilteredRoutes(response.data);
        const uniqueWalls = [...new Set(response.data.map(route => {
          const imageName = route.image.split('/').pop().replace('.jpg', '');
          return imageName;
        }))];
        setWalls(uniqueWalls);
      })
      .catch(error => {
        console.error('Error fetching routes', error);
      });
  }, []);

  useEffect(() => {
    filterRoutes();
  }, [searchWall, searchDifficulty]);

  const filterRoutes = () => {
    let filtered = routes;
    if (searchWall !== 'All') {
      filtered = filtered.filter(route => {
        const imageName = route.image.split('/').pop().replace('.jpg', '');
        return imageName === searchWall;
      });
    }
    if (searchDifficulty.min !== 'All' && searchDifficulty.max !== 'All') {
      const minDiff = parseDifficulty(searchDifficulty.min);
      const maxDiff = parseDifficulty(searchDifficulty.max);
      filtered = filtered.filter(route => {
        const routeDiff = parseDifficulty(route.difficulty);
        return routeDiff >= minDiff && routeDiff <= maxDiff;
      });
    }
    setFilteredRoutes(filtered);
  };

  const parseDifficulty = (difficulty) => {
    const gradeScale = {
      '4': 1, '4+': 2, '5': 3, '5+': 4,
      '6a': 5, '6a+': 6, '6b': 7, '6b+': 8,
      '6c': 9, '6c+': 10, '7a': 11, '7a+': 12,
      '7b': 13, '7b+': 14, '7c': 15, '7c+': 16,
      '8a': 17, '8a+': 18, '8b': 19, '8b+': 20,
      '8c': 21, '8c+': 22, '9a': 23,
    };
    return gradeScale[difficulty] || 0;
  };

  const handleCardClick = (route) => {
    setSelectedRoute(route);
  };

  const handleCloseModal = () => {
    setSelectedRoute(null);
  };

  return (
    <div className="view-routes-container">
      <h1>View  Routes</h1>
      <div className="filters">
        <div>
          <label>Search by wall:</label>
          <select value={searchWall} onChange={(e) => setSearchWall(e.target.value)}>
            <option value="All">All</option>
            {walls.map((wall, index) => (
              <option key={index} value={wall}>{wall}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Search by difficulty:</label>
          <div className="difficulty-range">
            <select value={searchDifficulty.min} onChange={(e) => setSearchDifficulty(prev => ({ ...prev, min: e.target.value }))}>
              <option value="All">All</option>
              <option value="4">4</option>
              <option value="4+">4+</option>
              <option value="5">5</option>
              <option value="5+">5+</option>
              <option value="6a">6a</option>
              <option value="6a+">6a+</option>
              <option value="6b">6b</option>
              <option value="6b+">6b+</option>
              <option value="6c">6c</option>
              <option value="6c+">6c+</option>
              <option value="7a">7a</option>
              <option value="7a+">7a+</option>
              <option value="7b">7b</option>
              <option value="7b+">7b+</option>
              <option value="7c">7c</option>
              <option value="7c+">7c+</option>
              <option value="8a">8a</option>
              <option value="8a+">8a+</option>
              <option value="8b">8b</option>
              <option value="8b+">8b+</option>
              <option value="8c">8c</option>
              <option value="8c+">8c+</option>
              <option value="9a">9a</option>
            </select>
            <span>to</span>
            <select value={searchDifficulty.max} onChange={(e) => setSearchDifficulty(prev => ({ ...prev, max: e.target.value }))}>
              <option value="All">All</option>
              <option value="4">4</option>
              <option value="4+">4+</option>
              <option value="5">5</option>
              <option value="5+">5+</option>
              <option value="6a">6a</option>
              <option value="6a+">6a+</option>
              <option value="6b">6b</option>
              <option value="6b+">6b+</option>
              <option value="6c">6c</option>
              <option value="6c+">6c+</option>
              <option value="7a">7a</option>
              <option value="7a+">7a+</option>
              <option value="7b">7b</option>
              <option value="7b+">7b+</option>
              <option value="7c">7c</option>
              <option value="7c+">7c+</option>
              <option value="8a">8a</option>
              <option value="8a+">8a+</option>
              <option value="8b">8b</option>
              <option value="8b+">8b+</option>
              <option value="8c">8c</option>
              <option value="8c+">8c+</option>
              <option value="9a">9a</option>
            </select>
          </div>
        </div>
      </div>
      <div className="route-cards-container">
        {filteredRoutes.map((route, index) => (
          <RouteCard key={index} route={route} onClick={() => handleCardClick(route)} />
        ))}
      </div>
      {selectedRoute && (
        <RouteModal route={selectedRoute} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default ViewRoutes;
